<template>
  <div>
    <v-row class="mb-12 pb-12">
      <v-col
        cols="12"
        v-for="(thought, index) in thoughts"
        :key="index"
        class="py-1"
      >
        <a
          class="wrapp_thought text-left pa-2"
          :class="[background_color(index)]"
          :href="thought.href"
          target="_blank"
        >
          <b>{{ thought.name }}</b>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "thoughts",
  data: () => ({
    thoughts: [
      {
        name: "Kosmos - Palabras griegas con significados curiosos",
        href: "https://aledeleonmoreno.com/kosmos",
      },
      {
        name: "100 ideas por Alejandro De León",
        href: "https://aledeleonmoreno.com/blog",
      },
      {
        name: "La historia de MICROWD contada en 40 capítulos",
        href: "https://www.amazon.es/historia-MICROWD-Contada-cap%C3%ADtulos-Alejandro-ebook/dp/B089KP6GK5",
      },
      {
        name: "Podcast - Libertad es poder hacer lo que debemos",
        href: "https://www.ivoox.com/podcast-libertad-es-poder-hacer-lo-debemos_sq_f1850941_1.html",
      },
      {
        name: "Instituto Juan de Mariana - ¿Qué es una empresa libertaria?",
        href: "https://www.juandemariana.org/ijm-actualidad/analisis-diario/que-es-una-empresa-libertaria",
      },
      {
        name: "VÍDEO: Alejandro sobre Capitalismo ¿social? [por Spanish Libertarian]",
        href: "https://www.youtube.com/watch?v=H_9273D6X-k",
      },
      {
        name: "VÍDEO: Cómo ayudar a las mujeres [por UTBH]",
        href: "https://www.youtube.com/watch?v=YFFeQor50bI",
      },
    ],
  }),
  methods: {
    ...mapMutations(["changeToSecondPage"]),
    background_color(index) {
      if (index === 0) return "bg_kosmos white--text";
      if (index === 1) return "bg_yellow";
      if (index === 2) return "bg_fluor";
    },
  },
};
</script>

<style scoped>
.bg_kosmos {
  background-color: #3886d6;
}

.bg_yellow {
  background-color: #ffc72a;
}

.bg_fluor {
  background-color: #01ffa9;
}

.wrapp_thought {
  color: #000000;
  border: 1px solid #000000;
  display: block;
  font-size: 1.1rem;
  text-decoration: none;
}
</style>
